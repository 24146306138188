import insta from 'common/src/assets/image/app/instagram/logo.svg';
import trans from 'common/src/assets/image/app/translations/logo.svg';
import follow from 'common/src/assets/image/app/followers/logo.svg';
import who from 'common/src/assets/image/agency/who/logo.svg';
import scanner from 'common/src/assets/image/agency/scanner/logo.svg';
import matdev from 'common/src/assets/image/agency/matdev/logo.svg';
import scannerapp from 'common/src/assets/image/app/scannerapp/logo.svg';
import videoup from 'common/src/assets/image/agency/videoup/logo.svg';
import collage from 'common/src/assets/image/app/collage/logo.svg';
import qr from 'common/src/assets/image/agency/qr/logo.svg';
import developerfaxer from 'common/src/assets/image/app/developerfaxer/logo.svg';

export function ProjectLogo(project) {
  const image = {
    instagram: insta,
    translations: trans,
    followers: follow,
    who: who,
    qr: qr,
    scanner: scanner,
    matdev: matdev,
    scannerapp: scannerapp,
    videoup: videoup,
    collage: collage,
    developerfaxer: developerfaxer,
  };
  return image[project];
}
